



































import { Vue, Component, Watch } from 'vue-property-decorator'
import { Ability } from '@/types/Ability'
import { mapGetters, mapState } from 'vuex'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { dateHumanizerDisplay, hoursMinutesToLocal } from '@/utils/helpers'
import { etat_seance } from '@/types/Seance'


@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('seanceAnomalie', ['seanceAnomalies', 'totalRows', 'loading', 'meta']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    },
    components: {
        ExaGenericTable
    }
})
export default class OrganisationExaminateurs extends Vue {
    Ability = Ability

    paramsLocal: any = {
        page: 1,
        sort: 'name',
        direction: 'asc'
    }

    filtres:    any         = []
    dataForTab: Array<any>  = []

    // Ensemble des colonnes du tableau
    genericfields = [
        { key: 'serie_name', label: 'Série', sortable: true, class: '', type: 'text' },
        { key: 'jour', label: 'Date', sortable: true, class: 'text-center', type: 'text' },
        { key: 'h_debut', label: 'Heure', sortable: true, class: 'text-center', type: 'text' },
        { key: 'centre_name', label: 'Centre', sortable: true, class: '', type: 'text' },
        { key: 'salle_name', label: 'Salle', sortable: true, class: '', type: 'text' },
        { key: 'ensemble', label: 'Équipe', sortable: true, class: '', type: 'text' },
        { key: 'user_name', label: 'Examinateur', sortable: true, class: '', type: 'text' },
        { key: 'groupe_name', label: 'Groupe', sortable: true, class: '', type: 'text' },
        { key: 'epreuve', label: 'Épreuve', sortable: true, class: '', type: 'text' },
        { key: 'code', label: 'Code', sortable: true, class: '', type: 'text' },
        { key: 'candidat_name', label: 'Candidat', sortable: true, class: '', type: 'text' },
        { key: 'concour_name', label: 'Filière', sortable: true, class: '', type: 'text' }
    ]


    // ---------------------------------------------


    // Chargement des données du tableau
    count_anomalie = 0
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            this.count_anomalie = 0
            for (const result of poData)
            {
                // const can = this.$store.getters['auth/can'](Ability.ORAL_AFEX_MANAGE)
                // const droit = checkIcone(Ability.ORAL_AFEX_MANAGE, can)

                let userName = ''

                if (result.user) {
                    userName = result.user.name + ' ' + result.user.first_name
                }

                // const userNameClass = 'text-start text-info item_action'

                // const matieres = this.getMatieresNames(result.epreuveCorrection)

                let serie = ''
                let centre = ''
                let epreuve = ''
                let code = ''
                let salle = ''
                let candidat = '' // etat_seance
                const classCandidat = ''
                let filiere = ''
                let  backgroundClass = ''
                const group = result.groupeExaminateur?.name ?? ''
                if (result.seances &&  result.seances.length > 0 && result.seances[0].candidat) {
                    candidat = '<div class="nameCandidat">' + result.seances[0].candidat.name + ' ' + result.seances[0].candidat.first_name + '</div>'
                }
                if (result.seances &&  result.seances.length > 0) {
                    if (result.seances[0].serie) {
                        serie = result.seances[0].serie.name
                    }
                    if (result.seances[0].salle) {
                        salle = result.seances[0].salle.name
                    }
                    if (result.seances[0].epreuveCorrection) {
                        epreuve = result.seances[0].epreuveCorrection.name
                    }
                    if (result.seances[0].epreuveCorrection) {
                        code = result.seances[0].epreuveCorrection.code
                    }

                    if (result.seances[0].etat === etat_seance.ETAT_NON_PLANIFIE || result.nonConsecutive) {
                        this.count_anomalie++
                    }

                    if (result.seances[0].etat === etat_seance.ETAT_NON_PLANIFIE) {
                        candidat  += '  <div class="text-danger">SÉANCE NON PLANIFIÉE</div>'
                        backgroundClass = ' bg-anomaly'
                    }
                    if (result.nonConsecutive) {
                        candidat  += '  <div class="text-danger">SÉANCE NON CONSÉCUTIVE</div>'
                        backgroundClass = ' bg-anomaly'
                    }
                } else {
                    candidat  += '  <div class="text-danger">SÉANCE NON PLANIFIÉE</div>'
                    backgroundClass = ' bg-anomaly'
                    this.count_anomalie++
                }

                if (result.ensemble) {
                    if (result.ensemble.centre) {
                        centre = result.ensemble.centre.name
                    }
                    filiere = this.getFiliereNameById(result.ensemble.concour_id)
                }




                const line = [
                    { label: '', item: serie, type: 'text', typeAction: null, class: '' + backgroundClass },
                    { label: '', item: dateHumanizerDisplay(result.jour), type: 'text', typeAction: null, class: 'text-center' + backgroundClass },
                    { label: '', item: hoursMinutesToLocal(result.h_debut), type: 'text', typeAction: null, class: 'text-center' + backgroundClass },
                    { label: '', item: centre, type: 'text', typeAction: null, class: 'text-start' + backgroundClass },
                    { label: '', item: salle, type: 'text', typeAction: null, class: '' + backgroundClass },
                    { label: '', item: result.ensemble.name, type: 'text', typeAction: null, class: '' + backgroundClass },
                    { label: '', item: userName, type: 'text', typeAction: null, class: '' + backgroundClass },
                    { label: '', item: group, type: 'text', typeAction: null, class: '' + backgroundClass },
                    // { label: '', item: result.user.id, type: 'actionText', typeAction: 'infoIntervenant', class: userNameClass, text: userName },
                    { label: '', item: epreuve, type: 'text', typeAction: null, class: '' + backgroundClass },
                    { label: '', item: code, type: 'text', typeAction: null, class: '' + backgroundClass },
                    { label: '', item: candidat, type: 'html', typeAction: null, class: classCandidat + backgroundClass },
                    // { label: '', item: matieres, type: 'text', typeAction: null, class: '' },
                    { label: '', item: filiere, type: 'text', typeAction: null, class: 'mt-0' + backgroundClass }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    getMatieresNames (data: any) {
        if (data && data.matiere) {
            return data.matiere.name
        } else {
            return ''
        }
        /*
        let matieres = ''
        if (data.matieres && data.matieres.length > 0) {
            for (let i  = 0; i < data.matieres.length; i++) {
                if (matieres !== '') {
                    matieres += ', '
                }
                matieres += data.matieres[i].name
            }
        }
        return matieres
        */
    }

    getFiliereNameById (id: number) {
        for (let i = 0; i < this.$store.getters['concour/banques'].length; i++) {
            if (id === this.$store.getters['concour/banques'][i].id) {
                return this.$store.getters['concour/banques'][i].name
            }
        }
    }

    getFiliere(data: any) {
        return [
            ...new Set(data?.concours
                ?.map((concour: any) => concour.name) || [])
        ]?.join(', ') || ''
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     * this.$store.state.ensembleAffectationsExaminateurs.examinateursAffectations
     */
    setFiltersForGenericTab()
    {
        // Options filières
        const filieres = this.$store.getters['concour/banques']
        const options_filieres = []
        for (const f in filieres) {
            options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
        }
        const allGroups = this.$store.getters['groupeExaminateur/groupeExaminateurs']
        const optionsGroups = []
        for (const group of allGroups) {
            optionsGroups.push({ index: group.id, name: group.name })
        }

        const matieres = this.$store.getters['matiere/matieres']
        const options_matieres = []
        for (const m in matieres) {
            options_matieres.push({ index: matieres[m].id, name: matieres[m].name })
        }


        this.filtres =
        [
            { libelle: 'Centre d\'oral', defautOptionlibelle: 'Rechercher un', model: 'centre.name', value: '', index: 'centre.id', datas: '', loading: false, options: { type: 'form', fieldsKey: 'centre.name' } },
            { libelle: 'Filière', defautOptionlibelle: 'Rechercher une', model: 'concour_id', value: '', index: 'concour_id',  datas: options_filieres, loading: false, options: { type: 'deroulant', fieldsKey: 'concour_name' } },
            { libelle: 'Équipe', defautOptionlibelle: 'Rechercher une', model: 'ensemble_name', value: '', index: 'ensemble_name',  datas: '', loading: false, options: { type: 'form', fieldsKey: 'ensemble_name' } },
            { libelle: 'Nom de correcteur', defautOptionlibelle: 'Rechercher un', model: 'user.name', value: '', index: 'user.id', datas: '', loading: false, options: { type: 'form', fieldsKey: 'user.name' } },
            { libelle: 'Matière', defautOptionlibelle: 'Rechercher une', model: 'matiere_name', value: '', index: 'matiere_name', datas: '', loading: false, options: { type: 'form', fieldsKey: 'matiere_name' } },
            { libelle: 'Groupe', defautOptionlibelle: 'Rechercher par', model: 'groupe_examinateur_id', value: '', index: 'groupe_name', datas: optionsGroups, loading: false, options: { type: 'deroulant', fieldsKey: 'groupe_name' } }

            // Filtre sur remplaçant pas fonctionnel pour le moment côté back.
            // { libelle: 'Remplaçant',              defautOptionlibelle: 'Rechercher un',     model: 'remplacant.name',           value: '', index: 'remplacant.name',        datas: '',                  loading: rootStore.loading, options: { type: 'form',      fieldsKey: 'remplacant.name' } }
        ]
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0]) {
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break

                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break

                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break


                default:
                    break
            }
        }
    }

    // Appelle une page lors du scroll
    loadHandler (params: any) {
        // console.log('Dans loadHandler')
        this.$store.dispatch('seanceAnomalie/getMoreSeanceAnomalies', params).then((response) => {
            this.setDataForGenericTab(response.data.data)
        })
    }

    filtreSortHandler (params: any) {
        // console.log('Dans filtreSortHandler')
        if (JSON.stringify(params) !== JSON.stringify(this.paramsLocal)) {
            this.paramsLocal = params
            this.$store.dispatch('seanceAnomalie/getSeanceAnomalies', params).then((response) => {
                this.setDataForGenericTab(response.data.data)
            })
        }
    }

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    load() {
        const getConcoursActifs = Promise.resolve(this.$store.dispatch('concour/getConcoursActifs'))
        const getSeanceAnomalies = Promise.resolve(this.$store.dispatch('seanceAnomalie/getSeanceAnomalies'))
        const getGroupeExaminateurs = Promise.resolve(this.$store.dispatch('groupeExaminateur/getGroupeExaminateurs'))
        const arrayPromises = [getSeanceAnomalies, getGroupeExaminateurs]
        if (this.$store.getters['concour/banques'].length === 0) {
            arrayPromises.push(getConcoursActifs)
        }
        Promise.all(arrayPromises).then((responses) => {
            this.setDataForGenericTab(responses[0].data.data) // responses[0] is getSeanceAnomalies
            this.setFiltersForGenericTab()
        })
    }

    mounted () {
        this.load()
    }
}
